
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import install from '../assets/images/install.svg'
import { Button } from '@mui/material';

const PwaModal = () => {
    const [canInstall, setCanInstall] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isOpen, setIsOpen] = useState(true)

    useEffect(() => {

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setCanInstall(true);
        });
    }, []);

    const handleInstallClick = () => {
        if (canInstall && deferredPrompt) {
            // Show the install prompt
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
                setCanInstall(false);
            });
        }
    };

    // useEffect(() => {
    //     handleInstallClick()
    // }, [])

    return (
        <>
            {canInstall ?
                <Modal show={isOpen} centered onHide={e => setIsOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Install Application</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={install} alt="install" className='im-fluid' />
                        <div className="blue_btn">
                            <Button variant="contained" onClick={handleInstallClick}>Install Now</Button>
                        </div>
                    </Modal.Body>
                </Modal> : ""}
        </>
    )
}

export default PwaModal