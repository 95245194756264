import React from "react";
import { lazy, Suspense } from 'react';

import ConditionRoute from "./components/Route/ConditionRoute";

const Login = lazy(() => import('./pages/login.js'));
const Resetpassword = lazy(() => import('./pages/resetpassword.js'));
const Register = lazy(() => import('./pages/register.js'));
const ForgotPassword = lazy(() => import('./pages/forgot-password.js'));
const Wallet = lazy(() => import('./pages/wallet.js'));
const Dashboard = lazy(() => import('./pages/dashboard.js'));
const Profile = lazy(() => import('./pages/profile.js'));
const EmailVerification = lazy(() => import('./pages/EmailVerification.js'));
const Transaction = lazy(() => import('./pages/transaction.js'));
const PassBook = lazy(() => import('./pages/pass_book.js'));
const Help = lazy(() => import('./pages/help.js'));
const Notification = lazy(() => import('./pages/notification.js'));
const Terms = lazy(() => import('./pages/terms.js'));
const Contact = lazy(() => import('./pages/contact.js'));
const Support = lazy(() => import('./pages/support-ticket.js'));
const LogIn_Notification = lazy(() => import('./pages/logIn_Notification .js'));
const MaintenanceMode = lazy(() => import('./pages/Maintenance.js'));


const initialRoutes = [
  { path: "/", type: "auth", component: Login },
  { path: "/login", type: "auth", component: Login },
  { path: "/register", type: "auth", component: Register },
  { path: "/forgot-password", type: "auth", component: ForgotPassword },


  { path: "/email-verification/:authToken", type: "public", component: EmailVerification },
  { path: "/forgotPassword/:authToken", type: "public", component: EmailVerification },
  { path: "/withdraw-verification/:authToken", type: "public", component: EmailVerification },
  { path: "/reset-password/:authToken", type: "public", component: Resetpassword },
  { path: "/terms", type: "public", component: Terms },
  { path: "/privacy-policy", type: "public", component: Terms },
  { path: "/contact", type: "public", component: Contact },
  { path: "/maintenanceMode", type: "public", component: MaintenanceMode },


  { path: "/dashboard", type: "private", component: Dashboard },
  { path: "/wallet", type: "private", component: Wallet },
  { path: "/transaction", type: "private", component: Transaction },
  { path: "/help", type: "private", component: Help },
  { path: "/support-ticket", type: "private", component: Support },
  { path: "/profile", type: "private", component: Profile },
  { path: "/login-history", type: "private", component: LogIn_Notification },
  { path: "/pass-book", type: "private", component: PassBook },
  { path: "/notification", type: "private", component: Notification },
  { path: "/*", type: "private", component: Dashboard },
];



const AppWrapper = initialRoutes.map(({ type, component: Component, ...rest }) => {
  return {
    element: (
      <Suspense fallback={<span className="pageLoader"></span>}>
        <ConditionRoute type={type} >
          <Component />
        </ConditionRoute>
      </Suspense>
    ),
    ...rest,
  };
});
export default AppWrapper;
