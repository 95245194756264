// import config
import axios, { handleResp } from '../config/axios'

// import constant
import {
    SET_USER_WALLET_LIST,SET_CURRENCY,SET_PRICE_CONVERSION, SET_AVAILABLE_BALANCE,
    // SET_USER_FIRST_CURRENCY,
    // SET_USER_SECOND_CURRENCY,
    // UPDATE_USER_WALLET_STAKE
} from '../constant';

//redux 
import { currency, priceConversion, userWalletList } from "../redux/wallet/walletSlice";

let history_Cancel_axios;

export const getAssetData = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getWalletDetails`,
        });
        dispatch(userWalletList(respData?.data?.result))
        return true
    }
    catch (err) {
        console.log('ppppppppppppppppppp',err)
        handleResp(err, 'error')
        return false
    }
}


export const coinRequestVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'patch',
            'url': `/api/coinWithdraw`,
            'data': data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const transferCurrency = async ( data,dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/transfer`,
            'data': data
        });
        getAssetData(dispatch)
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const WithdrawReq = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/coinWithdraw`,
            'data': data
        });
        // getAssetData(dispatch)
        if(respData?.data?.status === "OTP") {
            return {
                status: "OTP",
                loading: false,
                message: respData.data.message,
            }
        }
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
        }
    }
}

export const getTrnxHistory = async (data) => {
    try {
        if (history_Cancel_axios) history_Cancel_axios.cancel();
        history_Cancel_axios = axios.CancelToken.source();

        let respData = await axios({
            'method': 'post',
            'url': `/api/transactionHistory`,
            data,
            cancelToken: history_Cancel_axios.token,
        });

        return {
            status: true,
            loading: false,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
            loading: false,
        }
    }
}




export const getCurrency = async (dispatch) => {
    try {
      let respData = await axios({
        method: "get",
        url: `/api/getCurrency`,
      });
    //   console.log(respData.data.result,'datadatadata')
    dispatch(currency(respData?.data?.result))
    //   dispatch(setCurrencyOption(respData.data.result));
      return {
        status: "success",
        loading: false,
        result: respData.data.result,
      };
    } catch (err) {
      handleResp(err, "error");
      return {
        status: "failed",
        loading: false,
      };
    }
  };


  export const setCurrencyOption = (data) => {
    return {
      type: SET_CURRENCY,
      data,
    };
  };

  export const getPriceConversion = async (dispatch) => {
    try {
      let respData = await axios({
        method: "get",
        url: `/api/priceConversion`,
      });
      dispatch(priceConversion(respData?.data?.result))
      return true;
    } catch (err) {
      handleResp(err, "error");
      return false;
    }
  };


  export const DepositRequest = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/depositrequest`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors
        }
    }
}
  

export const setAvailableBal = (
    {
        totalBal,
        lockedBal,
        USDTBal,
    },
    dispatch
) => {
    dispatch({
        type: SET_AVAILABLE_BALANCE,
        data: {
            totalBal,
            lockedBal,
            USDTBal,
        },
    });

    return true;
};
export const getPassbook = async (data) => {
    try {
        if (history_Cancel_axios) history_Cancel_axios.cancel();
        history_Cancel_axios = axios.CancelToken.source();

        let respData = await axios({
            'method': 'post',
            'url': `/api/getPassbook`,
            data,
            cancelToken: history_Cancel_axios.token,
        });

        return {
            status: true,
            loading: false,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
            loading: false,
        }
    }
}
